





























import {Component, Vue} from 'vue-property-decorator'
import HeaderMenu from '@/components/HeaderMenu.vue'
import StoreGroup from '@/components/store/StoreGroup.vue'
import Navigation from '@/components/Navigation.vue'
import outcastLogo from '@/assets/outcast-text.png'
import ContactsBlock from '@/components/ContactsBlock.vue'

@Component({
    components: {
        ContactsBlock,
        Navigation,
        HeaderMenu,
        StoreGroup,
    },
})
export default class MainContainer extends Vue {
    showNavigation = false

    isMobile = false

    get navigation() {
        return this.isMobile ? this.showNavigation : true
    }

    get logo() {
        return outcastLogo
    }

    onResize() {
        const newIsMobileValue = this.$vuetify.breakpoint.mobile

        if (newIsMobileValue !== this.$store.getters['isMobile']) {
            this.$store.commit('resize', this.$vuetify.breakpoint.mobile)
        }

        this.isMobile = newIsMobileValue
    }

    created() {
        window.addEventListener('resize', this.onResize)
    }

    mounted() {
        this.onResize()
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    }
}
