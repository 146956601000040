

































import {Component, Prop, Vue} from 'vue-property-decorator'
import {IStoreItem} from '@/components/store/store-item'

@Component
export default class StoreItem extends Vue {
    @Prop({required: true}) item!: IStoreItem

    goToLink() {
        this.$router.push(`item/${this.item.id}`)
    }
}
