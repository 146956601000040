export enum Category {
    FireShow = 'fire-show',
    PyroInstallation = 'pyro-installation',
    FireInstallation = 'fire-installation',
    //Firecrackers = 'firecrackers',
    HeartCeremony = 'heart-ceremony',
}

export interface IStoreItem {
    id?: number
    category: string
    photoUrls: Array<string>
    videoUrls: Array<string>
    title: string
    descriptions: Array<string>
    fullDescriptions: Array<string>
    additionalInfo?: IStorePageAdditionalInfo
    labels: Array<string>
}

export interface IStorePageAdditionalInfo {
    price: string
    artistsCount: string
    time: string
    additionals: Array<string>
}

export interface IStoreGroup {
    name: string
    items: Array<IStoreItem>
}

const presetItems: Array<IStoreItem> = [
    {
        title: 'SORRY "MAX"',
        category: Category.FireShow,
        photoUrls: [
            require('@/assets/media/main-group/sorry-max/3.jpg'),
            require('@/assets/media/main-group/sorry-max/1.jpg'),
            require('@/assets/media/main-group/sorry-max/2.jpg'),
            require('@/assets/media/main-group/sorry-max/4.jpg'),
            require('@/assets/media/main-group/sorry-max/5.jpg'),
            require('@/assets/media/main-group/sorry-max/6.jpg'),
            require('@/assets/media/main-group/sorry-max/7.jpg'),
            require('@/assets/media/main-group/sorry-max/9.jpg'),
        ],
        videoUrls: [],
        descriptions: ['Эффект !!!WOW!!!', 'По-настоящему жаркая постановка, драйв музыки и эксклюзивный реквизит не оставят никого равнодушным'],
        fullDescriptions: [
            '✔Высокий спрос ✔',
            '💥💥💥ЭФФЕКТ !!!WOW!!! 💥💥💥',
            '🔥Звук: акустическая система мощностью 500₩',
            '🔥Уйма Фоновой пиротехники',
            '🔥Эксклюзивный реквезит который не использует ни один коллектив Челябинской области',
            '+Церемония зажжения сердца 💕 в подарок',
        ],
        additionalInfo: {
            price: '28 000 руб',
            artistsCount: '4 артиста',
            time: '12-16 мин.',
            additionals: [
                '10 пар пиротехнических инструментов',
                'Цветной дым',
                'Фаер-огонь',
                'Cценический свет',
                'Огненные чаши',
            ]
        },
        labels: ['28 000 руб', '12-16 минут'],
    },
    {
        title: 'Sorry "min"',
        category: Category.FireShow,
        photoUrls: [
            require('@/assets/media/main-group/sorry-mini/3.jpg'),
            require('@/assets/media/main-group/sorry-mini/1.jpg'),
            require('@/assets/media/main-group/sorry-mini/2.jpg'),
            require('@/assets/media/main-group/sorry-mini/8.jpg'),
            require('@/assets/media/main-group/sorry-mini/5.jpg'),
            require('@/assets/media/main-group/sorry-mini/6.jpg'),
            require('@/assets/media/main-group/sorry-mini/7.jpg'),
        ],
        videoUrls: ['@/assets/media/main-group/sorry-mini/VID_20220717_114129.mp4'],
        descriptions: ['Хит сезона !!!', 'Живой огонь, искрометность, зажигательные треки - шоу, которое запомнится на долго'],
        fullDescriptions: [
            '🔥Выступление артистов: с 8-10 видами огненного реквизита',
            '🔥Оформление площадки :сценический свет ,огненные чаши.',
            '🔥Звук: акустическая система мощностью пол киловатт',
            '🔥Большое количество Фоновой пиротехники',
            '🔥Эксклюзивный реквезит который не использует ни один коллектив Челябинской области',
            '+Церемония зажжения сердца 💕 в подарок)',
        ],
        additionalInfo: {
            price: '17 000 руб',
            artistsCount: '3-4 артиста',
            time: '10-13 мин.',
            additionals: [
                '6-7 пар пиротехнических инструментов',
                'Цветной дым',
                'Фаер-огонь',
            ]
        },
        labels: ['17 000 руб', '10-13 минут'],
    },
    {
        title: 'Mystic',
        category: Category.FireShow,
        photoUrls: [
            require('@/assets/media/main-group/mystic/3.jpg'),
            require('@/assets/media/main-group/mystic/1.jpg'),
            require('@/assets/media/main-group/mystic/2.jpg'),
        ],
        videoUrls: [],
        fullDescriptions: [
            '🔥Выступление артистов: с 3-4 видами огненного реквизита',
            '🔥Звук: акустическая система мощностью 500w',
            '🔥Оформление площадки:огненные чаши ,сценический свет.',
            '🔥Звук:Собственная акустическая система'
        ],
        additionalInfo: {
            price: '13 000 руб',
            artistsCount: '2 артиста',
            time: '5-7 мин.',
            additionals: [
                'Пиротехника в руках артистов',
                'Цветной дым',
                'Фаер-огонь',
                'Фоновая пиротехника',
                'Cценический свет',
                '3 вида пиротехнического реквизита',
            ]
        },
        descriptions: ['Парное выступление,которое наполнено огнём, искрами и драйвом современной музыки'],
        labels: ['13 000 руб', '5-7 минут'],
    },
    {
        title: 'Monster',
        category: Category.FireShow,
        photoUrls: [
            require('@/assets/media/main-group/monstr/3.jpg'),
            require('@/assets/media/main-group/monstr/1.jpg'),
            require('@/assets/media/main-group/monstr/2.jpg'),
            require('@/assets/media/main-group/monstr/4.jpg'),
            require('@/assets/media/main-group/monstr/5.jpg'),

        ],
        videoUrls: [],
        fullDescriptions: [
            '🔥Выступление артистов: с 5 видами огненного реквизита',
            '🔥Оформление площадки :сценический свет ,огненные чаши',
            '🔥Звук: акустическая система мощностью 500w',
            '🔥Большое количество Фоновой пиротехники',
        ],
        additionalInfo: {
            price: '15 000 руб',
            artistsCount: '3 артиста',
            time: '7-9 мин.',
            additionals: [
                '4 пары пиротехнических инструментов',
                'Цветной дым',
                'Фаер-огонь',
                'Фоновая пиротехника',
                'Cценический свет',
                'огненные чаши',
            ]
        },
        descriptions: ['Энергия пламени для восхитительного праздника под всеми любимые зажигательные треки.'],
        labels: ['15 000 руб', '7-9 минут'],
    },
    {
        title: 'Пиротехнические вертушки (мельницы)',
        category: Category.PyroInstallation,
        photoUrls: [
            require('@/assets/media/pyro-group/mills/1.jpg'),
        ],
        videoUrls: [],
        descriptions: ['Романтичный ,головокружительный финал превзойдет все ваши ожидания.'],
        fullDescriptions: [
            'Динамично вращаеющая фигура, состоящая из фонтанов. Эффект, создваваемый крутящимися столпами искр, превзойдет все ваши ожидания.',
            'Вертушки могут быть как самостоятельным украшением праздника, так и прекрасным дополнением к церемонии зажжения сердца 💕.',
            'Вертушка на 6 фонтанов + дорожка из огненных чаш+музыкальное сопровождения',
            'Услуги пиротехника входят в стоимость.',
        ],
        labels: ['6 000 руб - 1 шт'],
    },
    {
        title: 'Огнепады',
        category: Category.PyroInstallation,
        photoUrls: [
            require('@/assets/media/pyro-group/firefall/1.jpg'),
        ],
        videoUrls: [],
        descriptions: ['Бесконечно льющийся поток огня,станет прекрасной кульминацией вечера'],
        fullDescriptions: [
            'Стена из струй холодного огня и искр, напоминающий огненный дождь или водопад.',
            'Миллионы серебряных капелек и искр падают с высоты вниз,создавая один непрерывный поток.',
            'Огнепады, дорожка из огненны чаши,музыкальное сопровождение',
            'Услуги пиротехника входят в стоимость',
        ],
        labels: ['от 10шт 7 500 руб'],
    },
    {
        title: 'Инсталяции из холодных фонтанов',
        category: Category.PyroInstallation,
        photoUrls: [
            require('@/assets/media/pyro-group/cold-fountains/1.jpg'),
        ],
        videoUrls: [],
        fullDescriptions: [
            'Один из самых доступных и ярких вариантов украшения важного дня.',
            'Фонтаны наземные, а это значит вы оказываетесь прямо внутри сверкающих искр.',
        ],
        descriptions: ['Яркое, оригинальное и впечатляющее завершение вашего торжества'],
        labels: ['от 900р за шт'],
    },
    {
        title: 'ОГНЕННЫЕ БУКВЫ',
        category: Category.FireInstallation,
        photoUrls: [
            require('@/assets/media/fire-group/symbols/1.jpg'),
        ],
        videoUrls: [],
        descriptions: ['Огненные надписи, популярный элемент украшения торжества.'],
        fullDescriptions: [
            'Огненные надписи, популярный элемент украшения торжества.',
            'Очень важно, то что Огненные инсталяции подходят под любое мероприятие.',
            'С помощью них вы можете поздравить молодожёнов, юбиляров, коллег и тд.',
            'Просто признаться в любви или же сделать, то самое волнительное предложение руки и сердца.',
        ],
        labels: ['1 500 руб за символ'],
    },
    {
        title: 'ОГНЕННЫЕ ЦИФРЫ',
        category: Category.FireInstallation,
        photoUrls: [
            require('@/assets/media/fire-group/numbers/1.jpg'),
        ],
        videoUrls: [],
        descriptions: ['Огненные надписи, популярный элемент украшения торжества.'],
        fullDescriptions: [
            'Огненные надписи, популярный элемент украшения торжества.',
            'Очень важно, то что Огненные инсталяции подходят под любое мероприятие.',
            'С помощью них вы можете поздравить молодожёнов ,юбиляров ,коллег и тд.',
            'Просто признаться в любви или же сделать, то самое волнительное предложение руки и сердца.',
        ],
        labels: ['1 500 руб за символ'],
    },
    {
        title: 'Церемония зажжения сердца',
        category: Category.HeartCeremony,
        photoUrls: [
            require('@/assets/media/heart-group/9.jpg'),
            require('@/assets/media/heart-group/13.jpg'),
            require('@/assets/media/heart-group/3.jpg'),
            require('@/assets/media/heart-group/4.jpg'),
            require('@/assets/media/heart-group/5.jpg'),
            require('@/assets/media/heart-group/6.jpg'),
            require('@/assets/media/heart-group/7.jpg'),
            require('@/assets/media/heart-group/8.jpg'),
            require('@/assets/media/heart-group/10.jpg'),
            require('@/assets/media/heart-group/2.jpg'),
            require('@/assets/media/heart-group/11.jpg'),
            require('@/assets/media/heart-group/12.jpg'),
            require('@/assets/media/heart-group/1.jpg'),
        ],
        videoUrls: [],
        descriptions: [
            'Пылающее пламенем сердце, зажженое от рук молодожёнов, столб холодных искр вырывающийся вверх - эта настоящая феерия не оставит никого равнодушным',
        ],
        fullDescriptions: [
            'Романтичная Церемония зажжения сердца 💕',
            'Пылающее пламенем сердце, зажженое от рук молодожёнов',
            'Столб холодных искр вырывающийся вверх, настоящая феерия не оставит ни кого равнодушным',
            'Сердце, дорожка из огненных чаш, музыкальное сопровождение(можете выбрать самостоятельно), Услуги пиротехника',
            'Далее +900р за фонтан',
            'Отличное дополнение :Пиротехнические вертушки, фейерверк.',
        ],
        labels: ['от 4800 руб'],
    },
]

const items = presetItems.map((item, index) => {
    return { ...item, id: index + 1 }
},
)

export const Data: { [key: string]: IStoreGroup } = {
    [Category.FireShow]: {
        name: 'Огненные шоу-программы',
        items: items.filter(item => item.category === Category.FireShow),
    },
    [Category.PyroInstallation]: {
        name: 'Пиротехнические инсталляции',
        items: items.filter(item => item.category === Category.PyroInstallation),
    },
    [Category.FireInstallation]: {
        name: 'Огненные инсталляции',
        items: items.filter(item => item.category === Category.FireInstallation),
    },
    //[Category.Firecrackers]: {
    //    name: 'Фейерверки',
    //    items: items.filter(item => item.category === Category.Firecrackers),
    //},
    [Category.HeartCeremony]: {
        name: 'Церемония зажжения огненного сердца',
        items: items.filter(item => item.category === Category.HeartCeremony),
    },
}

export function getItemById(id: number) {
    if (id <= 0 || id > items.length) {
        throw new Error('Unrecognized item id')
    }

    return items[id - 1]
}