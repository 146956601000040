
























import {Component, Prop, Vue} from 'vue-property-decorator'
import {Category, Data} from '@/components/store/store-item'

@Component
export default class Navigation extends Vue {
    @Prop({required: true}) show!: boolean

    get items() {
        const tabs = []

        for (const item in Category) {
            // @ts-ignore
            tabs.push({title: Data[Category[item]].name, path: Category[item]})
        }

        return tabs
    }

    goToLink(path: string) {
        this.$router.push({path: '/'})
        this.$router.push({path: path})
    }
}
