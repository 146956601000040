
















































import { Component, Vue } from 'vue-property-decorator'
import { getItemById } from '@/components/store/store-item'

@Component
export default class StoreItemPage extends Vue {
    get item() {
        return getItemById(parseInt(this.$route.params['id']))
    }
}
