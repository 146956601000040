import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isMobile: false,
    },
    mutations: {
        resize(state, value) {
            state.isMobile = value
        }
    },
    getters: {
        isMobile: (state, getters) => {
            return state.isMobile
        }
    },
    actions: {},
    modules: {},
})
