import { render, staticRenderFns } from "./StoreItemPage.vue?vue&type=template&id=3582702e&scoped=true&"
import script from "./StoreItemPage.vue?vue&type=script&lang=ts&"
export * from "./StoreItemPage.vue?vue&type=script&lang=ts&"
import style0 from "./StoreItemPage.vue?vue&type=style&index=0&id=3582702e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3582702e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VCarousel,VCarouselItem,VChip})
