
































import Component from 'vue-class-component'
import {Vue} from 'vue-property-decorator'
import instagram from '@/assets/instagram-icon.png'
import vk from '@/assets/vk-icon.png'

interface ILink {
    url: string,
    icon: any,
    alt: string,
}

@Component
export default class ContactsBlock extends Vue {

    links: Array<ILink> = [
        {
            url: 'https://instagram.com/outcast_ognennoe_show/',
            icon: instagram,
            alt: 'Instagram',
        },
        {
            url: 'https://vk.com/fireshow74_outcast_ognennoe_show',
            icon: vk,
            alt: 'Vk',
        },
    ]
}
