import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import StoreItemPage from '@/components/store/StoreItemPage.vue'
import {Category} from '@/components/store/store-item'
import StoreGroup from '@/components/store/StoreGroup.vue'
import NotFoundPage from '@/components/NotFoundPage.vue'

Vue.config.productionTip = false

Vue.use(VueRouter)


const customRoutes = Object.keys(Category)
    .map(key => {
        // @ts-ignore
        return {path: `/${Category[key]}`, meta: {key: key}, component: StoreGroup}
    })

const router = new VueRouter({
    mode: 'history',
    routes: [
        {path: '/', redirect: `/${Category.FireShow}`, component: StoreGroup},
        {path: '/item/:id', component: StoreItemPage},
        {path: '/404', name: '404', component: NotFoundPage},
        {path: '*', redirect: '/404'},
        ...customRoutes
    ],
})

new Vue({
    store,
    vuetify,
    render: h => h(App),
    router,
}).$mount('#app')