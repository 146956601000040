








import {Component, Vue} from 'vue-property-decorator'
import outcastLogo from '@/assets/outcast-text.png'

@Component
export default class HeaderMenu extends Vue {

    get logo() {
        return outcastLogo
    }
}
