








import Vue from 'vue'
import MainContainer from '@/components/MainContainer.vue'

export default Vue.extend({
	name: 'App',

	components: {
		MainContainer,
	},

	data: () => ({
		//
	}),
})
