
















import {Component, Vue} from 'vue-property-decorator'
import {Category, Data, IStoreItem} from '@/components/store/store-item'
import StoreItem from '@/components/store/StoreItem.vue'

@Component({
    components: {StoreItem},
})
export default class StoreGroup extends Vue {
    get items(): Array<IStoreItem> {
        // @ts-ignore
        return Data[Category[this.$route.meta['key']]].items
    }

    get cols() {
        return this.$vuetify.breakpoint.mobile ? 12 : 4
    }
}
